<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal wrap">
      <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'">
        <Cora />
      </v-flex>
      <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'">
        <RequerimentosEmAberto />
      </v-flex>
      <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'">
        <Matriculas />
      </v-flex>
      <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'">
        <ListagemDeAlunos />
      </v-flex>
      <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'">
        <CalendarioAcademico />
      </v-flex>
    </div>
  </layout>
</template>

<script>
import Notificacoes from "./components/Notificacoes";
import Link from "./components/Link";
import ListagemDeAlunos from "./components/ListagemDeAlunos";
import CalendarioAcademico from "./components/CalendarioAcademico";
import Matriculas from "./components/Matriculas";
import RequerimentosEmAberto from "./components/RequerimentosEmAberto";
import Cora from "./components/Cora.vue";

export default {
  components: {
    Notificacoes,
    Link,
    Cora,
    ListagemDeAlunos,
    CalendarioAcademico,
    Matriculas,
    RequerimentosEmAberto
  },
  created() {
    // this.listarDashboard()
  }
};
</script>
