<template>
  <v-card
    dark
    color="#333"
    height="120"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div style="width: 100%" class="expande-horizontal mb-5 pb-1 pt-0">
        <input
          id="foo"
          :value="myLink"
          style="color: #e2e2e2; width: 100%; border: 1px solid #777; border-radius: 5px; padding: 5px; height: 40px; margin-right: 6px;"
        />
        <button
          @click="
            createGlobalMessage({
              type: 'success',
              message: 'Link copiado com sucesso!',
              timeout: 3000
            })
          "
          data-clipboard-target="#foo"
          style="background-color: #222;border-radius: 6px;width: 40px; height: 40px;"
        >
          <v-icon :color="$theme.primary">mdi-content-save</v-icon>
        </button>
      </div>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>

    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <!-- <span style="font-size: 8pt;" class="fonte"> Total em Vendas </span> -->
        <span class="fonteTitulo grey--text">LINK DE INDICAÇÃO</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-icon :color="$theme.primary"> mdi-network-outline </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      myLink: ""
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"])
  },
  created() {
    this.myLink = `https://app.mbinvestimentos.com/#/signup/${this.getLoggedUser.nickname}`;
  },
  mounted() {
    new ClipboardJS(".btn");
  }
};
</script>
